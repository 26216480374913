import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';
import './App.css';
import { 
  Grid, Typography, Link,
  Theme, useMediaQuery
} from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import logo from './assets/logo.png';
import bg from './assets/background.png';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      color: '#ffffff',
      width: '100vw',
      overflowX: 'hidden'
    },
    header: {
      background: theme.palette.secondary.main,
      height: 'auto',
      padding: 15,
      textAlign: 'center',
      borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    navLogo: {
      width: 75,
    },
    navText: {
      color: '#ffffff',
      paddingBottom: 15
    },
    body: {
      // backgroundImage: `url(${bg})`,
      background: `linear-gradient(90deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 45%)`,
      textAlign: 'center',
      width: '100%',
      padding: theme.spacing(4),
      '@media(max-width: 600px)': {
        padding: theme.spacing(2)
      }
    },
    navLink: {
      color: '#ffffff !important',
      textDecoration: 'none',
      textDecorationColor: '#ffffff !important'
    },
    desc: {
      color: theme.palette.secondary.main,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: '100%',
      textAlign: 'center',
      '@media(max-width: 600px)': {}
    },
    confidential: {
      paddingBottom: theme.spacing(2),
      textDecoration: 'underline'
    },
    bodyLogo: {
      width: 150,
      marginLeft: 'auto',
      marginRight: 'auto',
      display:'block'
    },
    warning: {
      textAlign: 'center',
    },
    warningText: {
      fontWeight: '600 !important',
      color: theme.palette.common.white,
    },
    historyLink: {
      fontWeight: '600 !important',
      color: theme.palette.common.white,
      marginTop: theme.spacing(2)
    }
  }));

const Contents = () => {
    const isMobile = useMediaQuery("(max-width: 600px)");
    const classes = useStyles();

    return (
        <div className={classes.root}>
      <Grid container>

        <Grid item xs={12} className={classes.header}>
          <img src={logo} alt='Amani Logo' className={classes.navLogo} />
          <Typography variant={isMobile ? 'h5' : 'h4'} className={classes.navText}>
            Amani Crisis Intervention Services
          </Typography>
          <Typography variant={isMobile ? 'body2' : 'h6'}>
            Amani Crisis Intervention Services exists to serve all victims of abuse. To seek help, call our helpline or enter your information below.
          </Typography>
        </Grid>
        <div className={classes.body}>
          <Typography variant={isMobile ? 'h6' : 'h5'} className={classes.confidential}>
            <b>ALL INFORMATION IS CONFIDENTIAL!</b>
          </Typography>
          <Typography variant={isMobile ? 'body1' : 'h5'}>
            24-Hour Confidential Helpline Number
          </Typography>
          <Typography variant={isMobile ? 'h6' : 'h5'}>
            <Link href='tel:7139065318' className={classes.navLink}>713-906-5318</Link>
          </Typography>
          <Typography variant={isMobile ? 'body1' : 'h6'}>
            If this is an emergency, please call 911.
          </Typography>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <iframe
            title="Amani Form"
            // onLoad={() => window.parent.scrollTo(0,0)}
            // allow="geolocation; microphone; camera"
            src="https://forms.clickficks.com/app/amani-cis"
            style={{width: '100%', height: 539, border: 'none'}}
            // scrolling="no"
          />
        </Grid>
        <Grid item xs={12}>
          <div  className={classes.desc}>
            <Typography variant='body1'>
              When we step in help begins and violence ends.<br/>We provide mental wellness services, legal services, healthcare services, DV shelters, counseling services and education and prevention services.
            </Typography>
          </div>
        </Grid>
        <div className={classes.body}>
          <Grid item xs={12} className={classes.warning}>
            <Typography variant={isMobile ? 'body1' : 'h6'} className={classes.warningText}>
              IMPORTANT: Please delete your browser history to ensure there are no traces of you submitting this form!
            </Typography>
            <a 
                href="https://www.howtogeek.com/304218/how-to-clear-your-history-in-any-browser/" 
                target='_blank'
                className={classes.historyLink}
              >
                Show Me How To Clear History
              </a>
          </Grid>
        </div>
      </Grid>
    </div>
    )
}

export default Contents;