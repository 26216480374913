import './App.css';
import { 
  createTheme, ThemeProvider
} from '@mui/material';
import Contents from './contents';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5D095D',
      light: '#5D095D',
      dark: '#5D095D',
      contrastText: '#000',
    },
    secondary: {
      main: '#800080',
      light: '#800080',
      dark: '#800080',
      contrastText: '#fff',
    },
  },
  components:{
    MuiTextField: {}
  }

});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Contents />
    </ThemeProvider>
  );
}

export default App;
